import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '../../assets/img/svg/whatsapp.svg'


const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header d-flex justify-content-between" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = ["data-bs-target"]
const _hoisted_5 = ["data-bs-target"]
const _hoisted_6 = { class: "card-body p-0 mx-3 mt-3 position-relative z-index-1" }
const _hoisted_7 = { class: "container text-center" }
const _hoisted_8 = {
  key: 0,
  class: "fa-solid fa-spinner fa-10x circle my-0"
}
const _hoisted_9 = {
  key: 1,
  class: "img w-50 mx-auto",
  src: _imports_0,
  alt: "Connected"
}
const _hoisted_10 = {
  key: 2,
  class: "fa-solid fa-circle-exclamation fa-10x mt-5 mb-3"
}
const _hoisted_11 = {
  key: 3,
  class: "mt-2"
}
const _hoisted_12 = { class: "progress mx-2 my-4" }
const _hoisted_13 = ["aria-valuenow"]
const _hoisted_14 = ["id"]
const _hoisted_15 = { class: "container text-center" }
const _hoisted_16 = { class: "card-footer pt-2" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = {
  key: 0,
  class: "col"
}
const _hoisted_19 = ["disabled"]
const _hoisted_20 = {
  key: 1,
  class: "col"
}
const _hoisted_21 = {
  key: 2,
  class: "col d-flex justify-content-end mt-0 mb-3"
}
const _hoisted_22 = { class: "row mx-1 mt-3" }
const _hoisted_23 = { class: "form-check form-switch" }
const _hoisted_24 = ["checked", "disabled"]
const _hoisted_25 = { class: "d-flex flex-column justify-content-center" }
const _hoisted_26 = { class: "mb-0" }
const _hoisted_27 = {
  key: 0,
  class: "alert p-1 ps-2 text-white text-sm alert-danger"
}
const _hoisted_28 = { class: "row mx-1 mt-3" }
const _hoisted_29 = { class: "form-check form-switch" }
const _hoisted_30 = ["checked", "disabled"]
const _hoisted_31 = { class: "mb-0" }
const _hoisted_32 = {
  key: 0,
  class: "text-sm text-secondary my-0"
}
const _hoisted_33 = { class: "row mx-1 mt-3" }
const _hoisted_34 = { class: "form-check form-switch" }
const _hoisted_35 = ["checked", "disabled"]
const _hoisted_36 = { class: "mb-0" }
const _hoisted_37 = {
  key: 0,
  class: "text-sm"
}
const _hoisted_38 = { class: "row mx-1 mt-3" }
const _hoisted_39 = { class: "form-check form-switch" }
const _hoisted_40 = ["checked", "disabled"]
const _hoisted_41 = { class: "form-check-label" }
const _hoisted_42 = ["id", "aria-labelledby"]
const _hoisted_43 = {
  class: "modal-dialog modal-dialog-centered",
  role: "document"
}
const _hoisted_44 = { class: "modal-content px-2" }
const _hoisted_45 = { class: "modal-header" }
const _hoisted_46 = { class: "modal-title" }
const _hoisted_47 = { class: "modal-body" }
const _hoisted_48 = { class: "card-footer text-end" }
const _hoisted_49 = ["id", "aria-labelledby"]
const _hoisted_50 = {
  class: "modal-dialog modal-dialog-centered",
  role: "document"
}
const _hoisted_51 = { class: "modal-content px-2" }
const _hoisted_52 = { class: "modal-header" }
const _hoisted_53 = { class: "modal-title" }
const _hoisted_54 = { class: "modal-body" }
const _hoisted_55 = { class: "card-footer text-end" }

import WhatsAppClient from '@/services/gordaApi/WhatsAppClient'
import {onBeforeUnmount, onMounted, ref, Ref} from 'vue'
import QRCode from 'qrcode'
import {ClientObserver} from '@/services/gordaApi/ClientObserver'
import {useWpClientsStore} from '@/services/stores/WpClientStore'
import {LoadingType} from '@/types/LoadingType'
import {WpClient} from "@/types/WpClient";
import {hide} from "@/helpers/ModalHelper";
import {storeToRefs} from "pinia";
import {WhatsappServices} from "@/constants/WhatsappServices";

interface Props {
  client: WpClient
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Connection',
  props: {
    client: {}
  },
  setup(__props: any) {

const props = __props
const qr: Ref<string|null> = ref(null)
const connected: Ref<boolean> = ref(false)
const connecting: Ref<boolean> = ref(false)
const loading: Ref<LoadingType|null> = ref(null)
const {enableWpNotifications, onWpNotification, offWpNotifications, deleteClient, setDefault, enableChatBot, enableAssistant} = useWpClientsStore()
const {defaultClient} = storeToRefs(useWpClientsStore())

let wpClient: WhatsAppClient
let observer: ClientObserver

function auth() {
  connecting.value = true
  wpClient.auth()
}

const onUpdate = (socket: WhatsAppClient): void => {
  connecting.value = false
  qr.value = socket.qr
  loading.value = socket.loading
  if (qr.value) QRCode.toCanvas(document.getElementById(props.client.id), qr.value as string, (e) => {console.log(e)})
  connected.value = socket.isConnected()
  connecting.value = socket.isConnecting()
}

async function deleteWpClient(): Promise<void> {
  hide('delete-client' + props.client.id)
  wpClient.destroy()
  await deleteClient(props.client)
}

async function restartWpClient(): Promise<void> {
  hide('restart-client' + props.client.id)
  wpClient.reset()
  connecting.value = true
}

onBeforeUnmount(() => {
  offWpNotifications(props.client)
  wpClient.detach(observer)
})

onMounted(() => {
  onWpNotification(props.client)
  wpClient = WhatsAppClient.getInstance(props.client)
  observer = new ClientObserver(onUpdate)
  wpClient.attach(observer)
  connected.value = wpClient.isConnected()
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h4", null, _toDisplayString(_ctx.client.alias), 1),
          _createElementVNode("h6", null, _toDisplayString(connected.value ? _ctx.$t('common.chatBot.connected') : _ctx.$t('common.chatBot.disconnected')), 1)
        ]),
        _createElementVNode("button", {
          class: "btn btn-primary btn-rounded me-2",
          "data-bs-target": '#restart-client' + _ctx.client.id,
          "data-bs-toggle": "modal"
        }, _cache[7] || (_cache[7] = [
          _createElementVNode("em", { class: "fa fa-rotate" }, null, -1)
        ]), 8, _hoisted_4),
        _createElementVNode("button", {
          class: "btn btn-danger btn-rounded",
          "data-bs-target": '#delete-client' + _ctx.client.id,
          "data-bs-toggle": "modal"
        }, _cache[8] || (_cache[8] = [
          _createElementVNode("em", { class: "fa fa-trash" }, null, -1)
        ]), 8, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          (connecting.value || loading.value)
            ? (_openBlock(), _createElementBlock("em", _hoisted_8))
            : _createCommentVNode("", true),
          (connected.value && !connecting.value)
            ? (_openBlock(), _createElementBlock("img", _hoisted_9))
            : _createCommentVNode("", true),
          (!connecting.value && !connected.value && !qr.value && !loading.value)
            ? (_openBlock(), _createElementBlock("em", _hoisted_10))
            : _createCommentVNode("", true),
          (loading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("h5", null, _toDisplayString(loading.value.message), 1),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", {
                    class: "progress-bar bg-success",
                    role: "progressbar",
                    style: _normalizeStyle('width: ' + loading.value.percent + '%'),
                    "aria-valuenow": loading.value.percent,
                    "aria-valuemin": "0",
                    "aria-valuemax": "100"
                  }, null, 12, _hoisted_13)
                ])
              ]))
            : _createCommentVNode("", true),
          _withDirectives(_createElementVNode("canvas", {
            class: "img img-fluid h-25 h-auto",
            id: props.client.id
          }, null, 8, _hoisted_14), [
            [_vShow, qr.value && !connected.value]
          ])
        ]),
        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.client.id), 1)
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          (!connected.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createElementVNode("button", {
                  class: "btn btn-primary",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (auth())),
                  disabled: connecting.value || loading.value || qr.value
                }, _toDisplayString(_ctx.$t('common.chatBot.connect')), 9, _hoisted_19)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_20)),
          (props.client.service === _unref(WhatsappServices).OFFICIAL)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createVNode(_component_router_link, {
                  to: { name: 'whatsapp.chat', params: {id: _ctx.client.id}},
                  tag: "a",
                  class: "btn btn-slack",
                  "data-original-title": "Chat"
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createElementVNode("em", { class: "fas fa-message" }, null, -1)
                  ])),
                  _: 1
                }, 8, ["to"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("input", {
              class: "form-check-input",
              name: "enable",
              type: "checkbox",
              checked: props.client.wpNotifications,
              disabled: !connected.value,
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_unref(enableWpNotifications)(props.client, !props.client.wpNotifications)), ["prevent"]))
            }, null, 8, _hoisted_24),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("h6", _hoisted_26, _toDisplayString(_ctx.$t('common.settings.wpNotifications')), 1),
              (connected.value && !props.client.wpNotifications && !props.client.chatBot && !props.client.assistant)
                ? (_openBlock(), _createElementBlock("p", _hoisted_27, _toDisplayString(_ctx.$t('common.settings.alert_notifications')), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("input", {
              class: "form-check-input",
              name: "enable",
              type: "checkbox",
              checked: props.client.assistant,
              disabled: !connected.value,
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_unref(enableAssistant)(props.client, !props.client.assistant)), ["prevent"]))
            }, null, 8, _hoisted_30),
            _createElementVNode("h6", _hoisted_31, _toDisplayString(_ctx.$t('common.settings.assistant')), 1),
            (connected.value && !props.client.assistant)
              ? (_openBlock(), _createElementBlock("p", _hoisted_32, _toDisplayString(_ctx.$t('common.settings.alert_assistant')), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("input", {
              class: "form-check-input",
              name: "enable",
              type: "checkbox",
              checked: props.client.chatBot,
              disabled: !connected.value,
              onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_unref(enableChatBot)(props.client, !props.client.chatBot)), ["prevent"]))
            }, null, 8, _hoisted_35),
            _createElementVNode("h6", _hoisted_36, _toDisplayString(_ctx.$t('common.settings.chatBot')), 1),
            (connected.value && !props.client.chatBot)
              ? (_openBlock(), _createElementBlock("p", _hoisted_37, _toDisplayString(_ctx.$t('common.settings.alert_chatBot')), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_38, [
          _createElementVNode("div", _hoisted_39, [
            _createElementVNode("input", {
              class: "form-check-input",
              name: "enable",
              type: "checkbox",
              checked: _unref(defaultClient) === _ctx.client.id,
              disabled: _unref(defaultClient) === _ctx.client.id,
              onChange: _cache[4] || (_cache[4] = ($event: any) => (_unref(setDefault)(props.client.id)))
            }, null, 40, _hoisted_40),
            _createElementVNode("label", _hoisted_41, _toDisplayString(_unref(defaultClient) === _ctx.client.id ? _ctx.$t('wp.placeholders.default') : _ctx.$t('wp.placeholders.select_default')), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: "modal fade",
      id: 'delete-client' + _ctx.client.id,
      tabindex: "-1",
      "aria-labelledby": 'delete-client' + _ctx.client.id,
      "aria-hidden": "true"
    }, [
      _createElementVNode("div", _hoisted_43, [
        _createElementVNode("div", _hoisted_44, [
          _createElementVNode("div", _hoisted_45, [
            _createElementVNode("h5", _hoisted_46, _toDisplayString(_ctx.$t('common.actions.delete')), 1),
            _cache[10] || (_cache[10] = _createElementVNode("button", {
              type: "button",
              id: "closeModalButton",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            }, [
              _createElementVNode("span", { "aria-hidden": "true" }, "×")
            ], -1))
          ]),
          _createElementVNode("div", _hoisted_47, _toDisplayString(_ctx.$t('wp.placeholders.delete')), 1),
          _createElementVNode("div", _hoisted_48, [
            _createElementVNode("button", {
              class: "btn btn-secondary me-2",
              type: "button",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(hide)('delete-client' + _ctx.client.id)))
            }, _toDisplayString(_ctx.$t('common.actions.cancel')), 1),
            _createElementVNode("button", {
              class: "btn btn-info",
              type: "button",
              onClick: deleteWpClient
            }, _toDisplayString(_ctx.$t('common.actions.delete')), 1)
          ])
        ])
      ])
    ], 8, _hoisted_42),
    _createElementVNode("div", {
      class: "modal fade",
      id: 'restart-client' + _ctx.client.id,
      tabindex: "-1",
      "aria-labelledby": 'restart-client' + _ctx.client.id,
      "aria-hidden": "true"
    }, [
      _createElementVNode("div", _hoisted_50, [
        _createElementVNode("div", _hoisted_51, [
          _createElementVNode("div", _hoisted_52, [
            _createElementVNode("h5", _hoisted_53, _toDisplayString(_ctx.$t('common.actions.restart')), 1),
            _cache[11] || (_cache[11] = _createElementVNode("button", {
              type: "button",
              id: "closeModalButton",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            }, [
              _createElementVNode("span", { "aria-hidden": "true" }, "×")
            ], -1))
          ]),
          _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.$t('wp.placeholders.restart')), 1),
          _createElementVNode("div", _hoisted_55, [
            _createElementVNode("button", {
              class: "btn btn-secondary me-2",
              type: "button",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_unref(hide)('restart-client' + _ctx.client.id)))
            }, _toDisplayString(_ctx.$t('common.actions.cancel')), 1),
            _createElementVNode("button", {
              class: "btn btn-info",
              type: "button",
              onClick: restartWpClient
            }, _toDisplayString(_ctx.$t('common.actions.restart')), 1)
          ])
        ])
      ])
    ], 8, _hoisted_49)
  ]))
}
}

})